.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 1535px) {
  .page-container {
    max-width: 1600px;
    margin-inline: auto;
  }
}


@media (min-width: 1536px) {
  .page-container {
    margin-inline: auto;
    max-width: 1900px;
  }
}

.ellipse-style {
  margin-top: 100px;
}

.slide-in-right {
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

.slide-out-right {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.background-dynamic {
  background-image: url(/public/assets/icons/Vector1.svg), url(/public/assets/images/Rectangle59.png);
  background-size: auto, cover;
  background-repeat: no-repeat, no-repeat;
}

@media (max-width: 600px) {
  .background-dynamic {
    background-position: center 13px, center top;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .background-dynamic {
    background-position: center 15px, center top;
  }
}

@media (min-width: 1025px) and (max-width: 1074px) {
  .background-dynamic {
    background-position: center -12px, center top;
  }
}

@media (min-width: 1075px) and (max-width: 1279px) {
  .background-dynamic {
    background-position: center -13px, center top;
  }
}

@media (min-width: 1280px) and (max-width: 1319px) {
  .background-dynamic {
    background-position: center -10px, center top;
  }
}

@media (min-width: 1320px) and (max-width: 1419px) {
  .background-dynamic {
    background-position: center -7px, center top;
  }
}

@media (min-width: 1420px) and (max-width: 1519px) {
  .background-dynamic {
    background-position: center 0px, center top;
  }
}

@media (min-width: 1520px) and (max-width: 1569px) {
  .background-dynamic {
    background-position: center 5px, center top;
  }
}

@media (min-width: 1570px) and (max-width: 1639px) {
  .background-dynamic {
    background-position: center 10px, center top;
  }
}

@media (min-width: 1640px) and (max-width: 1709px) {
  .background-dynamic {
    background-position: center 12px, center top;
  }
}

@media (min-width: 1710px) and (max-width: 1819px) {
  .background-dynamic {
    background-position: center 18px, center top;
  }
}

@media (min-width: 1820px) and (max-width: 1949px) {
  .background-dynamic {
    background-position: center 25px, center top;
  }
}

@media (min-width: 1950px) and (max-width: 2029px) {
  .background-dynamic {
    background-position: center 35px, center top;
  }
}

@media (min-width: 2030px) and (max-width: 2169px) {
  .background-dynamic {
    background-position: center 40px, center top;
  }
}

@media (min-width: 2170px) and (max-width:2299px) {
  .background-dynamic {
    background-position: center 48px, center top;
  }
}

@media (min-width: 2300px) and (max-width:2479px){
  .background-dynamic {
    background-position: center 58px, center top;
  }
}

@media (min-width: 2480px) {
  .background-dynamic {
    background-position: center 68px, center top;
  }
}


::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 12px !important;
}

::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}